<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap4SetupYourDevEnvironment"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 4: Setup your dev environment" image-name="4-setup-dev-env.jpg" image-alt="Setup your dev environment"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>How to install Go on your computer.</p></li>
<li><p>How to compute a checksum (SHA 256).</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Computer architecture</p></li>
<li><p>Hash Function</p></li>
<li><p>Compression / Decompression</p></li>
<li><p>Shell Profile</p></li>
<li><p>Path</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To write Go programs, you need an editor. To test, document, compile, format your Go programs, you will need to install the Go toolchain binaries.</p>
<p>This chapter will guide you through the installation process.</p>
<div id="computer-architecture" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Computer architecture <a href="#computer-architecture"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the following sections, you will need to know your <strong>computer architecture</strong>. This technical term sometimes brings confusion to newcomers. What is it exactly?</p>
<p>Computers come in different shapes, prices and forms. A lot of consumers focus on the brand on the computer and their high level specifications (like RAM, amount of storage on the hard drive). The question of the processor is sometimes eluded by marketing, but it’s a fundamental part of the computer. The processor is also called the CPU (<strong>C</strong>entral <strong>P</strong>rocessing <strong>U</strong>nit).</p>
<p>The <strong>CPU</strong> is responsible for running system instructions but also programs. CPU is a generic term. There are different types of CPUs available.</p>
<p>Programs use a set of predefined instructions to speak to the machine. We call this list of orders the instruction set. Some processors share the same instruction sets. Others have entirely different ones. The architecture notion covers the instruction set and the physical organization, and computer implementation.</p>
<p>You understand now why this notion is essential at the machine level. The Go toolchain is a collection of programs that we will use to build our programs.</p>
<ul>
<li><p>Those programs are written in Go and compiled.</p></li>
<li><p>The Go team provide different versions of the toolchain for each OS and architecture supported</p>
<ul>
<li><p>Go has a minimum system and OS requirements.</p></li>
<li><p>You can check them on this webpage:</p>
<ul>
<li><p>https://github.com/golang/go/wiki/MinimumRequirements.</p></li>
<li><p>The page is updated frequently.</p></li>
</ul></li>
</ul></li>
</ul>
<div id="go-toolchain-installation" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Go toolchain installation <a href="#go-toolchain-installation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To start developing a Go program, you have first to install Go on your computer. To install it, you will have to download a file. The installation procedure is slightly different depending on your OS (Operating System). Just follow the steps corresponding to your OS.</p>
<p>The first step (which does not depend on your OS) is to go to the official download page : <strong>https://golang.org/dl/</strong>. By the way, golang.org is the official Go website. We strongly advise you to download Go only from this URL.</p>
<figure>
<b-img :src="require('@/assets/images/go_dl_page.png')" alt="The Go download web page"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The Go download web page</figcaption>
</figure>
<p>In this section, we will cover Linux, macOS, and Windows. Note that go is also available for FreeBSD.</p>
<div id="linux" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> Linux <a href="#linux"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="determine-your-architecture" class="anchor"></div>
<h3 data-number="5.1.1"><span class="header-section-number">5.1.1</span> Determine your architecture <a href="#determine-your-architecture"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>You have to determine the <strong>architecture</strong> of your computer first. It will allow you to select the right file to download.</p>
<p>Open a terminal and type the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ uname -p</code></pre>
<p>Uname is a program that displays characteristics of your system. The flag <strong>-p</strong> will display “print the machine processor architecture name”.</p>
<p>Golang Linux versions are available for the following architectures :</p>
<ul>
<li><p>x86</p></li>
<li><p>x86-64</p></li>
<li><p>ARMv6</p></li>
<li><p>ARMv8</p></li>
<li><p>ppc64le</p></li>
<li><p>s390x</p></li>
</ul>
<div id="download-the-archive" class="anchor"></div>
<h3 data-number="5.1.2"><span class="header-section-number">5.1.2</span> Download the archive <a href="#download-the-archive"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>When you know your architecture, you can now download the corresponding compressed folder on the Go website :</p>
<figure>
<b-img :src="require('@/assets/images/linux_installation.png')" alt="Linux available downloads"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Linux available downloads</figcaption>
</figure>
<p>Just click on the blue link, and the download will begin.</p>
<p>In the following sections, we will install version 1.12.8 for the architecture x86-64. The instructions are the same for other architectures and other versions. Only the name of the archive will change.</p>
<div id="check-the-archive-hash" class="anchor"></div>
<h3 data-number="5.1.3"><span class="header-section-number">5.1.3</span> Check the archive hash <a href="#check-the-archive-hash"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>This step is strongly advised. The objective is to check that you do not have a corrupted version of the archive. To do that, open a terminal and type :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd /where/you/have/downloaded/the/file
$ sha256sum go1.12.8.linux-amd64.tar.gz</code></pre>
<p>The first command use cd (change directory) to go to the directory where the file you have downloaded has been put. For instance, if the file is in /home/max/ you have to execute :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd /home/max</code></pre>
<p>The next command will compute the <strong>SHA256</strong> hash (which is a cryptographic hash function) of the file <strong>go1.12.8.linux-amd64.tar.gz.</strong></p>
<p><strong><u>Cryptographic hash function</u></strong>: A function that will take as input variable size data (a file, a word, a sentence) and output a fixed size data blob. Having the output, it’s almost impossible to retrieve the function’s input. The result is called the “hash” or the “message-digest”. Here we use it to ensure that the file has not been altered in its way to our computer. To do that, we compare the hash that we computed on our computer with the hash provided on the Go website. If both are equal, there has been no alteration.</p>
<p>When the <strong>sha256sum</strong> has been executed, you will see on your screen a set of characters that you have to compare with the hash displayed on the website. If there are not identical, something went wrong, do not use the file downloaded. If the hash you have computed and the hash displayed on the Go website is equal, your good to go.</p>
<div id="decompress-the-archive" class="anchor"></div>
<h3 data-number="5.1.4"><span class="header-section-number">5.1.4</span> Decompress the archive <a href="#decompress-the-archive"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The archive is gzipped (compressed with <strong>gzip</strong>, it’s often called a <strong>tarball</strong>). To deflate it, you have two options :</p>
<ol type="1">
<li><p>Use the graphical interface (if you have one): double click on the archive and a window will open. Follow the instructions. You have to extract and deflate the files in <strong>/usr/local</strong></p></li>
<li><p>Use the terminal.</p></li>
</ol>
<p>We will take option 2.</p>
<p>Open your terminal and type the command :</p>
<pre v-highlightjs><code class="go" v-pre >$ sudo tar -C /usr/local -xzf go1.12.8.linux-amd64.tar.gz</code></pre>
<p>We will use the application tar (in sudo mode)</p>
<ul>
<li><p><strong>-C /usr/local</strong> : means that we will change the exection directory to <strong>/usr/local</strong></p></li>
<li><p><strong>-xzf go1.12.8.linux-amd64.tar.gz:</strong> means that we want to extract (x) the archive <strong>go1.12.8.linux-amd64.tar.gz</strong> that is compressed with gzip.</p></li>
</ul>
<div id="set-your-path" class="anchor"></div>
<h3 data-number="5.1.5"><span class="header-section-number">5.1.5</span> Set your PATH <a href="#set-your-path"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s take a look at the tree view of the folder <strong>/usr/local/go :</strong></p>
<figure>
<b-img :src="require('@/assets/images/tree_view_linux_go_folder.png')" alt="Tree view of /usr/local/go"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Tree view of /usr/local/go</figcaption>
</figure>
<p>We have eight folders: API, bin, doc, lib, misc, pkg, src, and tests.</p>
<p>The folder bin contains two executables :</p>
<ul>
<li><p><strong>go</strong> : this is the main executable</p></li>
<li><p><strong>godoc</strong> : this is a program used to generate documentation<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a></p></li>
<li><p><strong>gofmt</strong> : this program will format your source files according to the language conventions</p></li>
</ul>
<p>If you are into the bin directory, you can launch go. Try this command :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./go version</code></pre>
<p>It will print the go version. But this is not satisfying. We want to be able to open a terminal and run go –version everywhere. To do that, we need to add this folder to the PATH.</p>
<p><strong><u>PATH</u></strong>: an environment variable named PATH that contains a list of directories where shells will search for executable files when a user issues a command. You will often hear “add this directory to the path”. This expression means that you have to append a directory address to the list of directories in the PATH environment variable.</p>
<p>The following command will append “<strong>/usr/local/go/bin</strong>” to the PATH variable :</p>
<pre v-highlightjs><code class="go" v-pre >$ export PATH=$PATH:/usr/local/go/bin</code></pre>
<p>We set the variable PATH (with the export command) with the value already contained in it (denoted by <strong>$PATH</strong>) and we append to it the characters “:/usr/local/go/bin”. If you keep the same terminal open and type :</p>
<pre v-highlightjs><code class="go" v-pre >$ go version</code></pre>
<p>you will see the version of Go displayed.</p>
<figure>
<b-img :src="require('@/assets/images/go_version_terminal.png')" alt="go version output"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">go version output</figcaption>
</figure>
<h4 class="unnumbered" id="modify-your-shell-profile">Modify your shell profile</h4>
<p>Now, if you close your terminal and open another one, you will notice that it does not work anymore. The environment variable PATH has been modified only for your current session. When you open a new terminal, you create a new session. The modification made to PATH is propagated to new sessions.</p>
<p>To modify the PATH variable for each new session, you have to change your shell profile. You need to add “export PATH=$PATH:/usr/local/go/bin” at the beginning of the profile.</p>
<ul>
<li><p>If you use <strong>bash</strong> the file to modify is <strong>~/.bashrc</strong>. It is run whenever a session is started</p></li>
<li><p>If you use <strong>zsh</strong> the file to modify is <strong>~/.zshrc</strong>. It is run whenever a session is started</p></li>
<li><p>For other shells, refer to the documentation provided, but it should work the same</p></li>
</ul>
<p>When <strong>.bashrc</strong> or <strong>.zshrc</strong> has been modified, the change is not applied immediately. You need to start a new session to see the result.</p>
<p>If you do not want to start a new session, you can type this command and press enter :</p>
<pre v-highlightjs><code class="go" v-pre >$ source ~/.bashrc</code></pre>
<h4 class="unnumbered" id="test-the-installation">Test the installation</h4>
<p>Open a terminal and ask Go for its current installation version :</p>
<pre v-highlightjs><code class="go" v-pre >$ go version</code></pre>
<p>The version number should appear!</p>
<div id="macos" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> macOS <a href="#macos"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You have two main options to install go :</p>
<ol type="1">
<li><p>Use the installer</p></li>
<li><p>Download the binary and install it yourself.</p></li>
</ol>
<p>A third option would be to compile go from sources. For simplicity, we will show you how to use the installer.</p>
<div id="download-the-installer" class="anchor"></div>
<h3 data-number="5.2.1"><span class="header-section-number">5.2.1</span> Download the installer <a href="#download-the-installer"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Go to the website <strong>https://golang.org/dl/</strong> and download the appropriate installer for the version you desire. On the figure <a href="#fig:Download-the-macOS" data-reference-type="ref" data-reference="fig:Download-the-macOS">1</a> we have highlighted the line corresponding to the installer version 1.12.8 for macOS.</p>
<figure>
<b-img :src="require('@/assets/images/macOs-dl-formatted.png')" alt="Download the macOS installer[fig:Download-the-macOS]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Download the macOS installer<span id="fig:Download-the-macOS" label="fig:Download-the-macOS">[fig:Download-the-macOS]</span></figcaption>
</figure>
<div id="check-the-installer-hash" class="anchor"></div>
<h3 data-number="5.2.2"><span class="header-section-number">5.2.2</span> Check the installer hash <a href="#check-the-installer-hash"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>To verify the integrity of the file that downloaded, we will compute a SHA256 cryptographic hash. To do that, open a terminal window. Then type the commands :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd Downloads
$ shasum -a 256 go1.12.8.darwin-amd64.pkg    SHA_SUM  go1.12.8.darwin-amd64.pkg</code></pre>
<p>The first command will change the current directory (cd) to /Downloads. Then we will use the tool <strong>shasum</strong>. We add the flag a to say that we want to use the SHA256 hashing function. The result will be composed of the cryptographic hash followed by the name of the file hashed.</p>
<p>To verify the hash, go to the download page and check the SHA256 checksum column. You need to verify that the hash you get is the same as the one given by the go team.</p>
<p>Replace <strong>SHA_SUM</strong> by the value you copied on the website.</p>
<p>On figure <a href="#fig:Checksum-of-the-ma-installer" data-reference-type="ref" data-reference="fig:Checksum-of-the-ma-installer">2</a> you can see that we have equality between hashes.</p>
<figure>
<b-img :src="require('@/assets/images/checksum_go_dl_mac.png')" alt="The checksum of the Mac Installer[fig:Checksum-of-the-ma-installer]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The checksum of the Mac Installer<span id="fig:Checksum-of-the-ma-installer" label="fig:Checksum-of-the-ma-installer">[fig:Checksum-of-the-ma-installer]</span></figcaption>
</figure>
<p>Hashes that do not correspond means that the file that you downloaded has been somehow modified. You should not use it.</p>
<div id="launch-the-installer" class="anchor"></div>
<h3 data-number="5.2.3"><span class="header-section-number">5.2.3</span> Launch the installer <a href="#launch-the-installer"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Double click on the downloaded file. It will launch the installation wizard automatically. Follow the installation process. You will be asked for your password during the installation. The installer will put the files needed to run Go into the directory <strong>/usr/local/go</strong>.</p>
<h4 class="unnumbered" id="modify-your-shell-profile-1">Modify your shell profile</h4>
<p>Open a terminal and type :</p>
<pre v-highlightjs><code class="go" v-pre >$ go version</code></pre>
<p>If the version of go is displayed, then your good to go! Otherwise, you will need to add Go to your PATH environment variable.</p>
<p>You can follow the instruction in the previous section. They are the same for macOS.</p>
<div id="windows" class="anchor"></div>
<h2 data-number="5.3"><span class="header-section-number">5.3</span> Windows <a href="#windows"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In this section, we will show you how to install go with the <strong>.msi</strong> installer. This is the easiest method. But first, take a look at the specific requirements for Windows operating systems: https://github.com/golang/go/wiki/MinimumRequirements#windows.</p>
<div id="determine-your-architecture-1" class="anchor"></div>
<h3 data-number="5.3.1"><span class="header-section-number">5.3.1</span> Determine your architecture <a href="#determine-your-architecture-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Go for Windows is available for two architectures :</p>
<ul>
<li><p>x86, which correspond to 32 bits systems</p></li>
<li><p>x86-64, which correspond to 64 bits systems</p></li>
</ul>
<p>To know if your system is either 32 bits or 64 bits, you will have to check the system properties :</p>
<ul>
<li><p>For Windows 7</p>
<ul>
<li><p>Click on the Start button</p></li>
<li><p>Right-click on <strong>Computer</strong></p></li>
<li><p>Then click on <strong>Properties</strong></p></li>
<li><p>A window will show the <strong>System type</strong></p></li>
</ul></li>
<li><p>For Windows 10 and Windows 8.1</p>
<ul>
<li><p>Click on the <strong>Start</strong> button</p></li>
<li><p>Then go to <strong>Settings</strong>, then <strong>System</strong> and <strong>About</strong></p></li>
<li><p>A window will show the <strong>System type</strong></p></li>
</ul></li>
</ul>
<div id="download-the-installer-1" class="anchor"></div>
<h3 data-number="5.3.2"><span class="header-section-number">5.3.2</span> Download the installer <a href="#download-the-installer-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Now that you know your architecture, you can download the appropriate installer on the official Go website :</p>
<figure>
<b-img :src="require('@/assets/images/widows_installer_32_or_64.png')" alt="Checksum of the Mac Installer[fig:Checksum-of-the-ma-installer-1]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Checksum of the Mac Installer<span id="fig:Checksum-of-the-ma-installer-1" label="fig:Checksum-of-the-ma-installer-1">[fig:Checksum-of-the-ma-installer-1]</span></figcaption>
</figure>
<div id="check-the-installer-hash-1" class="anchor"></div>
<h3 data-number="5.3.3"><span class="header-section-number">5.3.3</span> Check the installer hash <a href="#check-the-installer-hash-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Once you have downloaded the correct installer, you have to check its integrity. To do that, we will compute the SHA256 hash of the downloaded file.</p>
<p>Windows has a builtin program called <strong>certutil.exe.</strong>This program is part of the “Certificate Service”. This program is located in <strong>C:\Windows\System32\certutil.exe</strong>. If you do not find it, I suggest you to launch a search into the Windows folder.</p>
<p>To compute the SHA256 hash, open a terminal and then type the following commands.</p>
<pre v-highlightjs><code class="go" v-pre >$ cd C:\users\max\downloads
$ certutil -hashfile go1.12.8.windows-amd64.msi SHA256
SHA256 hash of file go1.12.8.windows-amd64.msi:
98 39 25 49 90 de dc 56 47 bf 38 61 1f 7c 1a 7a fc 65 f7 fd 6a af f9 77 e0 5b 17 d4 ec 21 fe a6
CertUtil: -hash file command completed successfully.</code></pre>
<p>You have then to compare the hash computed by certutil to the one displayed on the golang.org website. The string output by certutil has spaces between each hexadecimal number. You have to copy the string and remove the spaces.</p>
<p>If both strings are equal, you can go to the next step. If not, you get a corrupted version. Do not use it.</p>
<div id="launch-the-installer-1" class="anchor"></div>
<h3 data-number="5.3.4"><span class="header-section-number">5.3.4</span> Launch the installer <a href="#launch-the-installer-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Double click on the installer and follow the instructions. At the end of the installation process, test your installation. Open a terminal and type the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go version</code></pre>
<p>If you see the version printed, you have finished your installation</p>
<div id="a-tour-of-go-environment-variables" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> A tour of Go environment variables <a href="#a-tour-of-go-environment-variables"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go use environment variables for its configuration. In this section, we will detail some of them :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">GOBIN</code></span>: By default, Go will place compiled programs into $GOPATH/bin. If you want to override this behavior, you can set this variable.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">GOROOT</code></span>: The absolute path where the Go distribution is installed (for Linux and macOS user, it’s by default <strong>/usr/local/go</strong>).</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">GOHOSTOS</code></span>: This is the operating system of the Go toolchain</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">GOHOSTARCH</code></span>: This is the system architecture of the Go toolchain binaries</p></li>
</ul>
<p>To print all the Go environment variables, you can use this command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go env</code></pre>
<p>Use this command when you have trouble with your Go settings. Here is the output of the command :</p>
<pre v-highlightjs><code class="go" v-pre >GO111MODULE=&quot;&quot;
GOARCH=&quot;amd64&quot;
GOBIN=&quot;&quot;
GOCACHE=&quot;/Users/maximilienandile/Library/Caches/go-build&quot;
GOENV=&quot;/Users/maximilienandile/Library/Application Support/go/env&quot;
GOEXE=&quot;&quot;
GOFLAGS=&quot;&quot;
GOHOSTARCH=&quot;amd64&quot;
GOHOSTOS=&quot;darwin&quot;
GOINSECURE=&quot;&quot;
GOMODCACHE=&quot;/Users/maximilienandile/go/pkg/mod&quot;
GONOPROXY=&quot;&quot;
GONOSUMDB=&quot;&quot;
GOOS=&quot;darwin&quot;
GOPATH=&quot;/Users/maximilienandile/go&quot;
GOPRIVATE=&quot;&quot;
GOPROXY=&quot;https://proxy.golang.org,direct&quot;
GOROOT=&quot;/usr/local/go&quot;
GOSUMDB=&quot;sum.golang.org&quot;
GOTMPDIR=&quot;&quot;
GOTOOLDIR=&quot;/usr/local/go/pkg/tool/darwin_amd64&quot;
GOVCS=&quot;&quot;
GOVERSION=&quot;go1.16&quot;
GCCGO=&quot;gccgo&quot;
AR=&quot;ar&quot;
CC=&quot;clang&quot;
CXX=&quot;clang++&quot;
CGO_ENABLED=&quot;1&quot;
GOMOD=&quot;/dev/null&quot;
CGO_CFLAGS=&quot;-g -O2&quot;
CGO_CPPFLAGS=&quot;&quot;
CGO_CXXFLAGS=&quot;-g -O2&quot;
CGO_FFLAGS=&quot;-g -O2&quot;
CGO_LDFLAGS=&quot;-g -O2&quot;
PKG_CONFIG=&quot;pkg-config&quot;
GOGCCFLAGS=&quot;-fPIC -arch x86_64 -m64 -pthread -fno-caret-diagnostics -Qunused-arguments -fmessage-length=0 -fdebug-prefix-map=/var/folders/lm/9l2tk4811x32rmw4407f9h3m0000gn/T/go-build744222834=/tmp/go-build -gno-record-gcc-switches -fno-common&quot;</code></pre>
<div id="common-errors" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Common errors <a href="#common-errors"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In this section, you can find the list of common issues encountered during Go’s installation.</p>
<div id="error-command-not-found-or-not-recognized-when-i-want-to-launch-the-go-binary" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Error “command not found” or “not recognized” when I want to launch the go binary <a href="#error-command-not-found-or-not-recognized-when-i-want-to-launch-the-go-binary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="symptom" class="anchor"></div>
<h3 data-number="7.1.1"><span class="header-section-number">7.1.1</span> Symptom <a href="#symptom"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>When using zsh shell (on Linux or Mac) :</p>
<pre v-highlightjs><code class="go" v-pre >$ go version
zsh: command not found: go</code></pre>
<p>When using bash shell (on Linux or Mac) :</p>
<pre v-highlightjs><code class="go" v-pre >$ go version
bash: command not found: go</code></pre>
<p>For Windows:</p>
<pre v-highlightjs><code class="go" v-pre >$ go version
&#39;go&#39; is not recognized as an internal or external command, operable program Or batch file.</code></pre>
<div id="explanation" class="anchor"></div>
<h3 data-number="7.1.2"><span class="header-section-number">7.1.2</span> Explanation <a href="#explanation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>This error message means that the “go” executable is not found by your shell. When you open a terminal, the shell will load the environment variable PATH (see the definition in the previous section). This variable contains a list of directory addresses on the filesystem. The shell will look in those directories if an executable file named “go” It can be found. If no executable named “go” can be found, it will output an error.</p>
<div id="solutions" class="anchor"></div>
<h3 data-number="7.1.3"><span class="header-section-number">7.1.3</span> Solutions <a href="#solutions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>Verify that the folder that contains the “go” binary (go/bin) is added to your PATH.</p>
<ul>
<li><p><u>For Linux and Mac users :</u></p>
<ul>
<li><p>Open a terminal and type “echo $PATH”</p></li>
<li><p>Check that the directory go/bin is in the output result</p>
<ul>
<li><p>$ echo $PATH /usr/bin:/bin:/usr/sbin:/sbin:/usr/local/go/bin</p></li>
<li><p>Here you can see that <strong>/usr/local/go/bin</strong> is in the path</p></li>
<li><p>If it’s not add <strong>/usr/local/go/bin</strong> to the path (follow the instruction provided in the previous section)</p></li>
</ul></li>
</ul></li>
<li><p><u>For Windows users :</u></p>
<ul>
<li><p>C:&gt; echo %PATH% C:;C:;C:</p></li>
<li><p>Here the setup is correct. The bin folder is in the path</p></li>
<li><p>If it’s not, add it to the PATH. To do so :</p>
<ul>
<li><p>open <strong>System</strong> &gt; <strong>Advanced system settings</strong></p></li>
<li><p>then click on the button <strong>Environment Variables</strong>. A window containing all environment variables should appear.</p></li>
<li><p>Click on “PATH”</p></li>
<li><p>Add a semicolon and then “C:\Go\bin”</p></li>
<li><p>Click OK then OK.</p></li>
<li><p>Close your terminal window and open another one. You should be good.</p></li>
</ul></li>
</ul></li>
</ul></li>
<li><p>If the previous solution did not work, you should check that the “go” binary is effectively present where you think it is.</p>
<ul>
<li><p><u>For Linux and Mac users :</u></p>
<ul>
<li>Go to <strong>/usr/local/go/bin</strong> and verify that you have a file named “go”</li>
</ul></li>
<li><p><u>For Windows users :</u></p>
<ul>
<li>Go to <strong>C:\Go\bin</strong> and verify that you have a file named “go”</li>
</ul></li>
<li><p>When for instance, the folder Go (or go) do not even exist, you will need to reinstall it. Maybe you put the installed binary into another directory?</p></li>
</ul></li>
</ul>
<div id="error-exec-format-error" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Error “exec format error” <a href="#error-exec-format-error"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="symptom-1" class="anchor"></div>
<h3 data-number="7.2.1"><span class="header-section-number">7.2.1</span> Symptom <a href="#symptom-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The go binary is found in your system, but when you launch a Go command you get an error :</p>
<pre v-highlightjs><code class="go" v-pre >$ go version
zsh: exec format error: go</code></pre>
<div id="explanation-1" class="anchor"></div>
<h3 data-number="7.2.2"><span class="header-section-number">7.2.2</span> Explanation <a href="#explanation-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>You probably downloaded a version that does not conform to your system. For instance, you download the binary for Linux and launch it on your mac.</p>
<div id="solution" class="anchor"></div>
<h3 data-number="7.2.3"><span class="header-section-number">7.2.3</span> Solution <a href="#solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Download the version that corresponds to your operating system and your computer architecture.</p>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to output the version number of Go?</p></li>
<li><p>Where to find the latest version of Go?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to output the version number of Go?</p>
<ol type="1">
<li><p>Open a terminal</p></li>
<li><p>Type</p>
<pre v-highlightjs><code class="go" v-pre >$ go version</code></pre></li>
<li><p>Press enter</p></li>
</ol></li>
<li><p>Where to find the latest version of Go?</p>
<ol type="1">
<li>The latest versions of Go are available on the official Go Website: https://golang.org/dl/</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>The Go toolchain is available on the Go official website</p></li>
<li><p>The Go toolchain is available on Windows, macOS, and Linux Operating systems</p></li>
<li><p>You should download the version that matches your OS and your Architecture.</p></li>
<li><p>After installing the Go toolchain, you can launch the <strong>go</strong> command with your favorite terminal.</p>
<ul>
<li>You might need to add the go binary to your PATH environment variable</li>
</ul></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>In most recent versions godoc has been removed from the bin directory.<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap4SetupYourDevEnvironment"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap3TheTerminal'}">
									<p><u><small>Previous</small></u></p>
									<p><small>The terminal</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap5FirstGoApplication'}">
									<p><u><small>Next</small></u></p>
									<p><small>First Go Application</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Setup your dev environment - Practical Go Lessons"
const description = "To write Go programs, you need an editor. To test, document, compile, format your Go programs, you will need to install the Go toolchain binaries.This chapter will guide you through the installation process."

export default {
  name: "Chap4SetupYourDevEnvironment",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
